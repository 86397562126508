import CONSTANTS from '../constants';
import { DataService } from '../dataService/dataService';

/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */

const Website = {
  changelogs: () => DataService.get('https://api.secran.com.br/wp-json/wp/v2/posts?categories=6'),
};

// Accesses
const Accesses = {
  list: (userId, date) => DataService.get(`accesses/list/${userId}/${date}`),
};

// Achievements
const Achievements = {
  list: () => DataService.get('achievements/list'),
  index: () => DataService.get('achievements/index'),
  get: id => DataService.get(`achievements/get/${id}`),
  add: data => DataService.post('achievements/save', data),
  edit: (id, data) => DataService.post(`achievements/save/${id}`, data),
  delete: id => DataService.post(`achievements/delete/${id}`),
  image: (id, data, { onUploadProgress = () => {} }) =>
    DataService.postFile(`achievements/image/${id}`, data, { onUploadProgress }),
};

// AchievementProducts
const AchievementProducts = {
  list: () => DataService.get('achievementProducts/list'),
  index: (params = {}) => DataService.get('achievementProducts/index', { params }),
  get: id => DataService.get(`achievementProducts/get/${id}`),
  add: data => DataService.post('achievementProducts/save', data),
  edit: (id, data) => DataService.post(`achievementProducts/save/${id}`, data),
  buy: (id, data) => DataService.post(`achievementProducts/buy/${id}`, data),
  delete: id => DataService.post(`achievementProducts/delete/${id}`),
  image: (id, data, { onUploadProgress = () => {} }) =>
    DataService.postFile(`achievementProducts/image/${id}`, data, { onUploadProgress }),
};

// Addresses
const Addresses = {
  get: (model, foreignKey) => DataService.get(`addresses/get/${model}/${foreignKey}`),
  add: data => DataService.post('addresses/save', data),
  edit: (id, data) => DataService.post(`addresses/save/${id}`, data),
  delete: id => DataService.post(`addresses/delete/${id}`),
};

// Assets
const Assets = {
  tree: () => DataService.get('assets/tree'),
  list: () => DataService.get('assets/list'),
  index: (params = {}) => DataService.get('assets/index', { params }),
  get: id => DataService.get(`assets/get/${id}`),
  add: data => DataService.post('assets/save', data),
  edit: (id, data) => DataService.post(`assets/save/${id}`, data),
  delete: id => DataService.post(`assets/delete/${id}`),
};

// AssetCategories
const AssetCategories = {
  tree: () => DataService.get('assetCategories/tree'),
  list: () => DataService.get('assetCategories/list'),
  index: () => DataService.get('assetCategories/index'),
  get: id => DataService.get(`assetCategories/get/${id}`),
  add: data => DataService.post('assetCategories/save', data),
  edit: (id, data) => DataService.post(`assetCategories/save/${id}`, data),
  delete: id => DataService.post(`assetCategories/delete/${id}`),
};

// Cache
const Cache = {
  clearAll: () => DataService.post('cache/clearAll'),
};

// CalendarHolidays
const CalendarHolidays = {
  list: () => DataService.get('calendarHolidays/list'),
  get: id => DataService.get(`calendarHolidays/get/${id}`),
  add: data => DataService.post('calendarHolidays/save', data),
  edit: (id, data) => DataService.post(`calendarHolidays/save/${id}`, data),
  delete: id => DataService.post(`calendarHolidays/delete/${id}`),
};

// Cards
const Cards = {
  report: (params = {}) => DataService.post('cards/report', { params }),
  questionsReport: (params = {}) => DataService.post('cards/questionsReport', { params }),
  employeesReport: (params = {}) => DataService.post('cards/employeesReport', { params }),
  summary: (
    view,
    permissionsPolicy = CONSTANTS.CARD_PERMISSIONS_POLICY.TEAM,
    departmentId = '',
    foreignKey = '',
    profileId = '',
    params = {},
  ) =>
    DataService.get(`cards/summary/${view}/${Number(permissionsPolicy)}/${departmentId}/${foreignKey}/${profileId}`, {
      params,
    }),
  index: (permissionsPolicy = CONSTANTS.CARD_PERMISSIONS_POLICY.TEAM, params = {}) =>
    DataService.get(`cards/index/${Number(permissionsPolicy)}`, { params }),
  files: (params = {}) => DataService.get('cards/files', { params }),
  filesDownload: uuid => DataService.download(`cards/filesDownload/${uuid}`),
  charts: (permissionsPolicy = CONSTANTS.CARD_PERMISSIONS_POLICY.TEAM, params = {}) =>
    DataService.get(`cards/charts/${Number(permissionsPolicy)}`, { params }),
  pendingChart: (permissionsPolicy = CONSTANTS.CARD_PERMISSIONS_POLICY.TEAM) =>
    DataService.get(`cards/pendingChart/${Number(permissionsPolicy)}`),
  get: id => DataService.get(`cards/get/${id}`),
  exists: data => DataService.post('cards/exists', data),
  add: data => DataService.post('cards/save', data),
  edit: (id, data) => DataService.post(`cards/save/${id}`, data),
  updateStatus: data => DataService.post(`cards/updateStatus`, data),
  delete: id => DataService.post(`cards/delete/${id}`),
  deleteMany: data => DataService.post(`cards/deleteMany`, data),
  transfer: data => DataService.post('cards/transfer', data),
};

// CardTypes
const CardTypes = {
  list: () => DataService.get('cardTypes/list'),
  index: () => DataService.get('cardTypes/index'),
  get: id => DataService.get(`cardTypes/get/${id}`),
  add: data => DataService.post('cardTypes/save', data),
  edit: (id, data) => DataService.post(`cardTypes/save/${id}`, data),
  delete: id => DataService.post(`cardTypes/delete/${id}`),
};

// CardPriorities
const CardPriorities = {
  list: () => DataService.get('cardPriorities/list'),
  index: () => DataService.get('cardPriorities/index'),
  get: id => DataService.get(`cardPriorities/get/${id}`),
  add: data => DataService.post('cardPriorities/save', data),
  edit: (id, data) => DataService.post(`cardPriorities/save/${id}`, data),
  delete: id => DataService.post(`cardPriorities/delete/${id}`),
};

// CardStatuses
const CardStatuses = {
  list: () => DataService.get('cardStatuses/list'),
  index: () => DataService.get('cardStatuses/index'),
  get: id => DataService.get(`cardStatuses/get/${id}`),
  add: data => DataService.post('cardStatuses/save', data),
  edit: (id, data) => DataService.post(`cardStatuses/save/${id}`, data),
  delete: id => DataService.post(`cardStatuses/delete/${id}`),
};

// CardJustifications
const CardJustifications = {
  index: cardId => DataService.get(`cardJustifications/index/${cardId}`),
  get: id => DataService.get(`cardJustifications/get/${id}`),
  add: data => DataService.post('cardJustifications/save', data),
  edit: (id, data) => DataService.post(`cardJustifications/save/${id}`, data),
  updateStatus: data => DataService.post(`cardJustifications/updateStatus`, data),
  saveMany: data => DataService.post(`cardJustifications/saveMany`, data),
  delete: id => DataService.post(`cardJustifications/delete/${id}`),
};

// CardJustificationReasons
const CardJustificationReasons = {
  list: () => DataService.get('cardJustificationReasons/list'),
  index: () => DataService.get('cardJustificationReasons/index'),
  get: id => DataService.get(`cardJustificationReasons/get/${id}`),
  add: data => DataService.post('cardJustificationReasons/save', data),
  edit: (id, data) => DataService.post(`cardJustificationReasons/save/${id}`, data),
  delete: id => DataService.post(`cardJustificationReasons/delete/${id}`),
};

// CardReviews
const CardReviews = {
  index: cardId => DataService.get(`cardReviews/index/${cardId}`),
  get: id => DataService.get(`cardReviews/get/${id}`),
  add: data => DataService.post('cardReviews/save', data),
  edit: (id, data) => DataService.post(`cardReviews/save/${id}`, data),
  delete: id => DataService.post(`cardReviews/delete/${id}`),
};

// CardMapViews
const CardMapViews = {
  index: () => DataService.get('cardMapViews/index'),
  map: (id, params = {}) => DataService.get(`cardMapViews/map/${id}`, { params }),
  get: id => DataService.get(`cardMapViews/get/${id}`),
  add: data => DataService.post('cardMapViews/save', data),
  edit: (id, data) => DataService.post(`cardMapViews/save/${id}`, data),
  delete: id => DataService.post(`cardMapViews/delete/${id}`),
};

// CardTemplates
const CardTemplates = {
  list: () => DataService.get('cardTemplates/list'),
  index: (params = {}) => DataService.get('cardTemplates/index', { params }),
  get: id => DataService.get(`cardTemplates/get/${id}`),
  add: data => DataService.post('cardTemplates/save', data),
  edit: (id, data) => DataService.post(`cardTemplates/save/${id}`, data),
  delete: id => DataService.post(`cardTemplates/delete/${id}`),
  transfer: data => DataService.post('cardTemplates/transfer', data),
  build: data => DataService.post('cardTemplates/build', data),
};

// CardTemplateRecurrences
const CardTemplateRecurrences = {
  list: () => DataService.get('cardTemplateRecurrences/list'),
  index: () => DataService.get('cardTemplateRecurrences/index'),
  get: id => DataService.get(`cardTemplateRecurrences/get/${id}`),
  add: data => DataService.post('cardTemplateRecurrences/save', data),
  edit: (id, data) => DataService.post(`cardTemplateRecurrences/save/${id}`, data),
  delete: id => DataService.post(`cardTemplateRecurrences/delete/${id}`),
};

// Clients
const Clients = {
  report: (params = {}) => DataService.post('clients/report', { params }),
  import: (cnpj, data = {}) => DataService.post(`clients/import/${cnpj}`, data),
  list: () => DataService.get('clients/list'),
  index: (params = {}) => DataService.get('clients/index', { params }),
  get: id => DataService.get(`clients/get/${id}`),
  add: data => DataService.post('clients/save', data),
  edit: (id, data) => DataService.post(`clients/save/${id}`, data),
  delete: id => DataService.post(`clients/delete/${id}`),
};

// ClientStatuses
const ClientStatuses = {
  list: () => DataService.get('clientStatuses/list'),
  index: () => DataService.get('clientStatuses/index'),
  charts: () => DataService.get('clientStatuses/charts'),
  get: id => DataService.get(`clientStatuses/get/${id}`),
  add: data => DataService.post('clientStatuses/save', data),
  edit: (id, data) => DataService.post(`clientStatuses/save/${id}`, data),
  delete: id => DataService.post(`clientStatuses/delete/${id}`),
};

// ClientTypes
const ClientTypes = {
  list: () => DataService.get('clientTypes/list'),
  index: () => DataService.get('clientTypes/index'),
  get: id => DataService.get(`clientTypes/get/${id}`),
  add: data => DataService.post('clientTypes/save', data),
  edit: (id, data) => DataService.post(`clientTypes/save/${id}`, data),
  delete: id => DataService.post(`clientTypes/delete/${id}`),
};

// ClientCardtemplates
const ClientCardTemplates = {
  report: (params = {}) => DataService.post('clientsCardTemplates/report', { params }),
};

// ClientAttorneys
const ClientAttorneys = {
  index: clientId => DataService.get(`clientAttorneys/index/${clientId}`),
  get: id => DataService.get(`clientAttorneys/get/${id}`),
  add: data => DataService.post('clientAttorneys/save', data),
  edit: (id, data) => DataService.post(`clientAttorneys/save/${id}`, data),
  delete: id => DataService.post(`clientAttorneys/delete/${id}`),
};

// ClientAttorneySigners
const ClientAttorneySigners = {
  list: () => DataService.get('clientAttorneySigners/list'),
  index: () => DataService.get('clientAttorneySigners/index'),
  get: id => DataService.get(`clientAttorneySigners/get/${id}`),
  add: data => DataService.post('clientAttorneySigners/save', data),
  edit: (id, data) => DataService.post(`clientAttorneySigners/save/${id}`, data),
  delete: id => DataService.post(`clientAttorneySigners/delete/${id}`),
};

// ClientAttorneyTypes
const ClientAttorneyTypes = {
  list: () => DataService.get('clientAttorneyTypes/list'),
  index: () => DataService.get('clientAttorneyTypes/index'),
  get: id => DataService.get(`clientAttorneyTypes/get/${id}`),
  add: data => DataService.post('clientAttorneyTypes/save', data),
  edit: (id, data) => DataService.post(`clientAttorneyTypes/save/${id}`, data),
  delete: id => DataService.post(`clientAttorneyTypes/delete/${id}`),
};

// ClientAttorneyGranteds
const ClientAttorneyGranteds = {
  list: () => DataService.get('clientAttorneyGranteds/list'),
  index: () => DataService.get('clientAttorneyGranteds/index'),
  get: id => DataService.get(`clientAttorneyGranteds/get/${id}`),
  add: data => DataService.post('clientAttorneyGranteds/save', data),
  edit: (id, data) => DataService.post(`clientAttorneyGranteds/save/${id}`, data),
  delete: id => DataService.post(`clientAttorneyGranteds/delete/${id}`),
};

// ClientCnpjs
const ClientCnpjs = {
  get: clientId => DataService.get(`clientCnpjs/get/${clientId}`),
  add: data => DataService.post('clientCnpjs/save', data),
  edit: (id, data) => DataService.post(`clientCnpjs/save/${id}`, data),
};

// ClientCnpjSizes
const ClientCnpjSizes = {
  list: () => DataService.get('clientCnpjSizes/list'),
  index: () => DataService.get('clientCnpjSizes/index'),
  get: id => DataService.get(`clientCnpjSizes/get/${id}`),
  add: data => DataService.post('clientCnpjSizes/save', data),
  edit: (id, data) => DataService.post(`clientCnpjSizes/save/${id}`, data),
  delete: id => DataService.post(`clientCnpjSizes/delete/${id}`),
};

// ClientCnpjStatuses
const ClientCnpjStatuses = {
  list: () => DataService.get('clientCnpjStatuses/list'),
  index: () => DataService.get('clientCnpjStatuses/index'),
  get: id => DataService.get(`clientCnpjStatuses/get/${id}`),
  add: data => DataService.post('clientCnpjStatuses/save', data),
  edit: (id, data) => DataService.post(`clientCnpjStatuses/save/${id}`, data),
  delete: id => DataService.post(`clientCnpjStatuses/delete/${id}`),
};

// ClientCnpjLegalNatures
const ClientCnpjLegalNatures = {
  list: () => DataService.get('clientCnpjLegalNatures/list'),
  index: () => DataService.get('clientCnpjLegalNatures/index'),
  get: id => DataService.get(`clientCnpjLegalNatures/get/${id}`),
  add: data => DataService.post('clientCnpjLegalNatures/save', data),
  edit: (id, data) => DataService.post(`clientCnpjLegalNatures/save/${id}`, data),
  delete: id => DataService.post(`clientCnpjLegalNatures/delete/${id}`),
};

// ClientCnpjMunicipalRegistrationStatuses
const ClientCnpjMunicipalRegistrationStatuses = {
  list: () => DataService.get('clientCnpjMunicipalRegistrationStatuses/list'),
  index: () => DataService.get('clientCnpjMunicipalRegistrationStatuses/index'),
  get: id => DataService.get(`clientCnpjMunicipalRegistrationStatuses/get/${id}`),
  add: data => DataService.post('clientCnpjMunicipalRegistrationStatuses/save', data),
  edit: (id, data) => DataService.post(`clientCnpjMunicipalRegistrationStatuses/save/${id}`, data),
  delete: id => DataService.post(`clientCnpjMunicipalRegistrationStatuses/delete/${id}`),
};

// ClientCnpjStateRegistrationStatuses
const ClientCnpjStateRegistrationStatuses = {
  list: () => DataService.get('clientCnpjStateRegistrationStatuses/list'),
  index: () => DataService.get('clientCnpjStateRegistrationStatuses/index'),
  get: id => DataService.get(`clientCnpjStateRegistrationStatuses/get/${id}`),
  add: data => DataService.post('clientCnpjStateRegistrationStatuses/save', data),
  edit: (id, data) => DataService.post(`clientCnpjStateRegistrationStatuses/save/${id}`, data),
  delete: id => DataService.post(`clientCnpjStateRegistrationStatuses/delete/${id}`),
};

// ClientCnpjEmployerUnions
const ClientCnpjEmployerUnions = {
  list: () => DataService.get('clientCnpjEmployerUnions/list'),
  index: () => DataService.get('clientCnpjEmployerUnions/index'),
  get: id => DataService.get(`clientCnpjEmployerUnions/get/${id}`),
  add: data => DataService.post('clientCnpjEmployerUnions/save', data),
  edit: (id, data) => DataService.post(`clientCnpjEmployerUnions/save/${id}`, data),
  delete: id => DataService.post(`clientCnpjEmployerUnions/delete/${id}`),
};

// ClientCnpjEmployeeUnions
const ClientCnpjEmployeeUnions = {
  list: () => DataService.get('clientCnpjEmployeeUnions/list'),
  index: () => DataService.get('clientCnpjEmployeeUnions/index'),
  get: id => DataService.get(`clientCnpjEmployeeUnions/get/${id}`),
  add: data => DataService.post('clientCnpjEmployeeUnions/save', data),
  edit: (id, data) => DataService.post(`clientCnpjEmployeeUnions/save/${id}`, data),
  delete: id => DataService.post(`clientCnpjEmployeeUnions/delete/${id}`),
};

// ClientCnpjEsocialSteps
const ClientCnpjEsocialSteps = {
  list: () => DataService.get('clientCnpjEsocialSteps/list'),
  index: () => DataService.get('clientCnpjEsocialSteps/index'),
  get: id => DataService.get(`clientCnpjEsocialSteps/get/${id}`),
  add: data => DataService.post('clientCnpjEsocialSteps/save', data),
  edit: (id, data) => DataService.post(`clientCnpjEsocialSteps/save/${id}`, data),
  delete: id => DataService.post(`clientCnpjEsocialSteps/delete/${id}`),
};

// ClientContracts
const ClientContracts = {
  report: (params = {}) => DataService.post('clientContracts/report', { params }),
  index: clientId => DataService.get(`clientContracts/index/${clientId}`),
  charts: () => DataService.get('clientContracts/charts'),
  get: id => DataService.get(`clientContracts/get/${id}`),
  add: data => DataService.post('clientContracts/save', data),
  edit: (id, data) => DataService.post(`clientContracts/save/${id}`, data),
  delete: id => DataService.post(`clientContracts/delete/${id}`),
};

// ClientContractItems
const ClientContractItems = {
  list: () => DataService.get('clientContractItems/list'),
  index: () => DataService.get('clientContractItems/index'),
  get: id => DataService.get(`clientContractItems/get/${id}`),
  add: data => DataService.post('clientContractItems/save', data),
  edit: (id, data) => DataService.post(`clientContractItems/save/${id}`, data),
  delete: id => DataService.post(`clientContractItems/delete/${id}`),
};

// ClientContractItemTypes
const ClientContractItemTypes = {
  list: () => DataService.get('clientContractItemTypes/list'),
  index: () => DataService.get('clientContractItemTypes/index'),
  get: id => DataService.get(`clientContractItemTypes/get/${id}`),
  add: data => DataService.post('clientContractItemTypes/save', data),
  edit: (id, data) => DataService.post(`clientContractItemTypes/save/${id}`, data),
  delete: id => DataService.post(`clientContractItemTypes/delete/${id}`),
};

// ClientDigitalCertificates
const ClientDigitalCertificates = {
  index: clientId => DataService.get(`clientDigitalCertificates/index/${clientId}`),
  get: id => DataService.get(`clientDigitalCertificates/get/${id}`),
  add: data => DataService.post('clientDigitalCertificates/save', data),
  edit: (id, data) => DataService.post(`clientDigitalCertificates/save/${id}`, data),
  delete: id => DataService.post(`clientDigitalCertificates/delete/${id}`),
};

// ClientDigitalCertificateTypes
const ClientDigitalCertificateTypes = {
  list: () => DataService.get('clientDigitalCertificateTypes/list'),
  index: () => DataService.get('clientDigitalCertificateTypes/index'),
  get: id => DataService.get(`clientDigitalCertificateTypes/get/${id}`),
  add: data => DataService.post('clientDigitalCertificateTypes/save', data),
  edit: (id, data) => DataService.post(`clientDigitalCertificateTypes/save/${id}`, data),
  delete: id => DataService.post(`clientDigitalCertificateTypes/delete/${id}`),
};

// ClientUsers
const ClientUsers = {
  index: clientId => DataService.get(`clientsUsers/index/${clientId}`),
  list: clientId => DataService.get(`clientsUsers/list/${clientId ?? ''}`),
  get: id => DataService.get(`clientsUsers/get/${id}`),
  add: data => DataService.post('clientsUsers/save', data),
  edit: (id, data) => DataService.post(`clientsUsers/save/${id}`, data),
  delete: id => DataService.post(`clientsUsers/delete/${id}`),
};

// ClientEmployees
const ClientEmployees = {
  report: (params = {}) => DataService.post('clientsEmployees/report', { params }),
  matchReport: (params = {}) => DataService.post('clientsEmployees/matchReport', { params }),
  listByDepartment: clientId => DataService.get(`clientsEmployees/listByDepartment/${clientId}`),
  index: clientId => DataService.get(`clientsEmployees/index/${clientId}`),
  get: id => DataService.get(`clientsEmployees/get/${id}`),
  add: data => DataService.post('clientsEmployees/save', data),
  edit: (id, data) => DataService.post(`clientsEmployees/save/${id}`, data),
  delete: id => DataService.post(`clientsEmployees/delete/${id}`),
  transfer: (action, data) => DataService.post(`clientsEmployees/transfer/${action}`, data),
};

// ClientFiles
const ClientFiles = {
  list: (clientId, departmentId, referenceDate) =>
    DataService.get(`clientsFiles/list/${clientId}/${departmentId}/${referenceDate}`),
  index: (params = {}) => DataService.get('clientsFiles/index', { params }),
  get: id => DataService.get(`clientsFiles/get/${id}`),
  add: data => DataService.post('clientsFiles/save', data),
  edit: (id, data) => DataService.post(`clientsFiles/save/${id}`, data),
  delete: (clientId, fileId) => DataService.post(`clientsFiles/delete/${clientId}/${fileId}`),
};

// ClientLicenses
const ClientLicenses = {
  index: clientId => DataService.get(`clientLicenses/index/${clientId}`),
  get: id => DataService.get(`clientLicenses/get/${id}`),
  add: data => DataService.post('clientLicenses/save', data),
  edit: (id, data) => DataService.post(`clientLicenses/save/${id}`, data),
  delete: id => DataService.post(`clientLicenses/delete/${id}`),
};

// ClientLicenseTypes
const ClientLicenseTypes = {
  list: () => DataService.get('clientLicenseTypes/list'),
  index: () => DataService.get('clientLicenseTypes/index'),
  get: id => DataService.get(`clientLicenseTypes/get/${id}`),
  add: data => DataService.post('clientLicenseTypes/save', data),
  edit: (id, data) => DataService.post(`clientLicenseTypes/save/${id}`, data),
  delete: id => DataService.post(`clientLicenseTypes/delete/${id}`),
};

// ClientNegativeCertificates
const ClientNegativeCertificates = {
  index: clientId => DataService.get(`clientNegativeCertificates/index/${clientId}`),
  get: id => DataService.get(`clientNegativeCertificates/get/${id}`),
  add: data => DataService.post('clientNegativeCertificates/save', data),
  edit: (id, data) => DataService.post(`clientNegativeCertificates/save/${id}`, data),
  delete: id => DataService.post(`clientNegativeCertificates/delete/${id}`),
};

// ClientNegativeCertificateSigners
const ClientNegativeCertificateSigners = {
  list: () => DataService.get('clientNegativeCertificateSigners/list'),
  index: () => DataService.get('clientNegativeCertificateSigners/index'),
  get: id => DataService.get(`clientNegativeCertificateSigners/get/${id}`),
  add: data => DataService.post('clientNegativeCertificateSigners/save', data),
  edit: (id, data) => DataService.post(`clientNegativeCertificateSigners/save/${id}`, data),
  delete: id => DataService.post(`clientNegativeCertificateSigners/delete/${id}`),
};

// ClientPartners
const ClientPartners = {
  index: clientId => DataService.get(`clientPartners/index/${clientId}`),
  get: id => DataService.get(`clientPartners/get/${id}`),
  add: data => DataService.post('clientPartners/save', data),
  edit: (id, data) => DataService.post(`clientPartners/save/${id}`, data),
  delete: id => DataService.post(`clientPartners/delete/${id}`),
};

// ClientPartnerQualifications
const ClientPartnerQualifications = {
  list: () => DataService.get('clientPartnerQualifications/list'),
  index: () => DataService.get('clientPartnerQualifications/index'),
  get: id => DataService.get(`clientPartnerQualifications/get/${id}`),
  add: data => DataService.post('clientPartnerQualifications/save', data),
  edit: (id, data) => DataService.post(`clientPartnerQualifications/save/${id}`, data),
  delete: id => DataService.post(`clientPartnerQualifications/delete/${id}`),
};

// ClientPartnerActs
const ClientPartnerActs = {
  index: clientId => DataService.get(`clientPartnerActs/index/${clientId}`),
  get: id => DataService.get(`clientPartnerActs/get/${id}`),
  add: data => DataService.post('clientPartnerActs/save', data),
  edit: (id, data) => DataService.post(`clientPartnerActs/save/${id}`, data),
  delete: id => DataService.post(`clientPartnerActs/delete/${id}`),
};

// ClientPartnerActSigners
const ClientPartnerActSigners = {
  list: () => DataService.get('clientPartnerActSigners/list'),
  index: () => DataService.get('clientPartnerActSigners/index'),
  get: id => DataService.get(`clientPartnerActSigners/get/${id}`),
  add: data => DataService.post('clientPartnerActSigners/save', data),
  edit: (id, data) => DataService.post(`clientPartnerActSigners/save/${id}`, data),
  delete: id => DataService.post(`clientPartnerActSigners/delete/${id}`),
};

// ClientPasswords
const ClientPasswords = {
  get: clientId => DataService.get(`clientPasswords/get/${clientId}`),
  add: data => DataService.post('clientPasswords/save', data),
  edit: (id, data) => DataService.post(`clientPasswords/save/${id}`, data),
};

// ClientTaxations
const ClientTaxations = {
  index: clientId => DataService.get(`clientTaxations/index/${clientId}`),
  get: id => DataService.get(`clientTaxations/get/${id}`),
  add: data => DataService.post('clientTaxations/save', data),
  edit: (id, data) => DataService.post(`clientTaxations/save/${id}`, data),
  delete: id => DataService.post(`clientTaxations/delete/${id}`),
};

// ClientTaxationCalculationTypes
const ClientTaxationCalculationTypes = {
  list: () => DataService.get('clientTaxationCalculationTypes/list'),
  index: () => DataService.get('clientTaxationCalculationTypes/index'),
  get: id => DataService.get(`clientTaxationCalculationTypes/get/${id}`),
  add: data => DataService.post('clientTaxationCalculationTypes/save', data),
  edit: (id, data) => DataService.post(`clientTaxationCalculationTypes/save/${id}`, data),
  delete: id => DataService.post(`clientTaxationCalculationTypes/delete/${id}`),
};

// ClientTaxationFederalTypes
const ClientTaxationFederalTypes = {
  list: () => DataService.get('clientTaxationFederalTypes/list'),
  index: () => DataService.get('clientTaxationFederalTypes/index'),
  get: id => DataService.get(`clientTaxationFederalTypes/get/${id}`),
  add: data => DataService.post('clientTaxationFederalTypes/save', data),
  edit: (id, data) => DataService.post(`clientTaxationFederalTypes/save/${id}`, data),
  delete: id => DataService.post(`clientTaxationFederalTypes/delete/${id}`),
};

// ClientTaxationStateTypes
const ClientTaxationStateTypes = {
  list: () => DataService.get('clientTaxationStateTypes/list'),
  index: () => DataService.get('clientTaxationStateTypes/index'),
  get: id => DataService.get(`clientTaxationStateTypes/get/${id}`),
  add: data => DataService.post('clientTaxationStateTypes/save', data),
  edit: (id, data) => DataService.post(`clientTaxationStateTypes/save/${id}`, data),
  delete: id => DataService.post(`clientTaxationStateTypes/delete/${id}`),
};

// ClientTaxationMunicipalTypes
const ClientTaxationMunicipalTypes = {
  list: () => DataService.get('clientTaxationMunicipalTypes/list'),
  index: () => DataService.get('clientTaxationMunicipalTypes/index'),
  get: id => DataService.get(`clientTaxationMunicipalTypes/get/${id}`),
  add: data => DataService.post('clientTaxationMunicipalTypes/save', data),
  edit: (id, data) => DataService.post(`clientTaxationMunicipalTypes/save/${id}`, data),
  delete: id => DataService.post(`clientTaxationMunicipalTypes/delete/${id}`),
};

// ClientTaxationSocialSecurityTypes
const ClientTaxationSocialSecurityTypes = {
  list: () => DataService.get('clientTaxationSocialSecurityTypes/list'),
  index: () => DataService.get('clientTaxationSocialSecurityTypes/index'),
  get: id => DataService.get(`clientTaxationSocialSecurityTypes/get/${id}`),
  add: data => DataService.post('clientTaxationSocialSecurityTypes/save', data),
  edit: (id, data) => DataService.post(`clientTaxationSocialSecurityTypes/save/${id}`, data),
  delete: id => DataService.post(`clientTaxationSocialSecurityTypes/delete/${id}`),
};

// ClientTaxBreaks
const ClientTaxBreaks = {
  index: clientId => DataService.get(`clientTaxBreaks/index/${clientId}`),
  get: id => DataService.get(`clientTaxBreaks/get/${id}`),
  add: data => DataService.post('clientTaxBreaks/save', data),
  edit: (id, data) => DataService.post(`clientTaxBreaks/save/${id}`, data),
  delete: id => DataService.post(`clientTaxBreaks/delete/${id}`),
};

// ClientTaxBreakSigners
const ClientTaxBreakSigners = {
  list: () => DataService.get('clientTaxBreakSigners/list'),
  index: () => DataService.get('clientTaxBreakSigners/index'),
  get: id => DataService.get(`clientTaxBreakSigners/get/${id}`),
  add: data => DataService.post('clientTaxBreakSigners/save', data),
  edit: (id, data) => DataService.post(`clientTaxBreakSigners/save/${id}`, data),
  delete: id => DataService.post(`clientTaxBreakSigners/delete/${id}`),
};

// Cnaes
const Cnaes = {
  list: () => DataService.get('cnaes/list'),
  index: () => DataService.get('cnaes/index'),
  get: id => DataService.get(`cnaes/get/${id}`),
  add: data => DataService.post('cnaes/save', data),
  edit: (id, data) => DataService.post(`cnaes/save/${id}`, data),
  delete: id => DataService.post(`cnaes/delete/${id}`),
};

// Comments
const Comments = {
  list: (model, foreignKey) => DataService.get(`comments/list/${model}/${foreignKey}`),
  add: data => DataService.post('comments/save', data),
  edit: (id, data) => DataService.post(`comments/save/${id}`, data),
  delete: id => DataService.post(`comments/delete/${id}`),
};

// Contacts
const Contacts = {
  list: (model, foreignKey) => DataService.get(`contacts/list/${model}/${foreignKey}`),
  get: id => DataService.get(`contacts/get/${id}`),
  add: data => DataService.post('contacts/save', data),
  edit: (id, data) => DataService.post(`contacts/save/${id}`, data),
  delete: id => DataService.post(`contacts/delete/${id}`),
};

// Countries
const Countries = {
  list: () => DataService.get('countries/list'),
  index: () => DataService.get('countries/index'),
  get: id => DataService.get(`countries/get/${id}`),
  add: data => DataService.post('countries/save', data),
  edit: (id, data) => DataService.post(`countries/save/${id}`, data),
  delete: id => DataService.post(`countries/delete/${id}`),
};

// Departments
const Departments = {
  list: () => DataService.get('departments/list'),
  tree: () => DataService.get('departments/tree'),
  index: () => DataService.get('departments/index'),
  get: id => DataService.get(`departments/get/${id}`),
  add: data => DataService.post('departments/save', data),
  edit: (id, data) => DataService.post(`departments/save/${id}`, data),
  delete: id => DataService.post(`departments/delete/${id}`),
};

// EconomicGroups
const EconomicGroups = {
  list: () => DataService.get('economicGroups/list'),
  index: (params = {}) => DataService.get('economicGroups/index', { params }),
  get: id => DataService.get(`economicGroups/get/${id}`),
  add: data => DataService.post('economicGroups/save', data),
  edit: (id, data) => DataService.post(`economicGroups/save/${id}`, data),
  delete: id => DataService.post(`economicGroups/delete/${id}`),
};

// EmailSents
const EmailSents = {
  list: (model, foreignKey) => DataService.get(`emailSents/list/${model}/${foreignKey}`),
  listByEmail: email => DataService.get(`emailSents/listByEmail/${email}`),
  add: data => DataService.post('emailSents/save', data),
  edit: (id, data) => DataService.post(`emailSents/save/${id}`, data),
  delete: id => DataService.post(`emailSents/delete/${id}`),
};

// Files
const Files = {
  charts: () => DataService.get('files/charts'),
  list: (model, foreignKey) => DataService.get(`files/list/${model}/${foreignKey}`),
  index: (params = {}) => DataService.get('files/index', { params }),
  sentIndex: (params = {}) => DataService.get('files/sentIndex', { params }),
  download: uuid => DataService.download(`files/download/${uuid}`),
  multipleDownload: uuids => DataService.download(`files/multipleDownload/${uuids}`),
  get: id => DataService.get(`files/get/${id}`),
  add: (data, { onUploadProgress }) => DataService.postFile('files/save', data, { onUploadProgress }),
  edit: (id, data) => DataService.post(`files/save/${id}`, data),
  delete: id => DataService.post(`files/delete/${id}`),
};

// FileCategories
const FileCategories = {
  tree: () => DataService.get('fileCategories/tree'),
  list: () => DataService.get('fileCategories/list'),
  index: () => DataService.get('fileCategories/index'),
  get: id => DataService.get(`fileCategories/get/${id}`),
  add: data => DataService.post('fileCategories/save', data),
  edit: (id, data) => DataService.post(`fileCategories/save/${id}`, data),
  delete: id => DataService.post(`fileCategories/delete/${id}`),
};

// Histories
const Histories = {
  index: (model, foreignKey) => DataService.get(`histories/index/${model}/${foreignKey}`),
};

// Newsletters
const Newsletters = {
  read: data => DataService.post(`newsletters/read`, data),
  list: () => DataService.get('newsletters/list'),
  index: () => DataService.get('newsletters/index'),
  get: id => DataService.get(`newsletters/get/${id}`),
  add: data => DataService.post('newsletters/save', data),
  edit: (id, data) => DataService.post(`newsletters/save/${id}`, data),
  delete: id => DataService.post(`newsletters/delete/${id}`),
};

// Notifications
const Notifications = {
  read: data => DataService.post(`notifications/read`, data),
  list: () => DataService.get('notifications/list'),
  index: (params = {}) => DataService.get('notifications/index', { params }),
  get: id => DataService.get(`notifications/get/${id}`),
};

// OpenaiAssistants
const OpenaiAssistants = {
  list: () => DataService.get('openaiAssistants/list'),
  index: () => DataService.get('openaiAssistants/index'),
  get: id => DataService.get(`openaiAssistants/get/${id}`),
  add: data => DataService.post('openaiAssistants/save', data),
  edit: (id, data) => DataService.post(`openaiAssistants/save/${id}`, data),
  delete: id => DataService.post(`openaiAssistants/delete/${id}`),
};

// OpenaiThreads
const OpenaiThreads = {
  list: () => DataService.get(`openaiThreads/list`),
  get: id => DataService.get(`openaiThreads/get/${id}`),
  add: data => DataService.post('openaiThreads/save', data),
  edit: (id, data) => DataService.post(`openaiThreads/save/${id}`, data),
  delete: id => DataService.post(`openaiThreads/delete/${id}`),
  create: (openaiAssistantId, data) => DataService.post(`openaiThreads/create/${openaiAssistantId}`, data),
  message: (id, data) => DataService.post(`openaiThreads/message/${id}`, data),
  run: id => DataService.get(`openaiThreads/run/${id}`),
  upload: (data, { onUploadProgress = () => {} }) =>
    DataService.postFile('openaiThreads/upload', data, { onUploadProgress }),
};

// Profiles
const Profiles = {
  list: () => DataService.get('profiles/list'),
  index: () => DataService.get('profiles/index'),
  get: id => DataService.get(`profiles/get/${id}`),
  add: data => DataService.post('profiles/save', data),
  edit: (id, data) => DataService.post(`profiles/save/${id}`, data),
  delete: id => DataService.post(`profiles/delete/${id}`),
};

// QuestionTypes
const QuestionTypes = {
  list: () => DataService.get('questionTypes/list'),
  index: () => DataService.get('questionTypes/index'),
  get: id => DataService.get(`questionTypes/get/${id}`),
  add: data => DataService.post('questionTypes/save', data),
  edit: (id, data) => DataService.post(`questionTypes/save/${id}`, data),
  delete: id => DataService.post(`questionTypes/delete/${id}`),
};

// QueuedJobs
const QueuedJobs = {
  index: (params = {}) => DataService.get('queuedJobs/index', { params }),
  charts: () => DataService.get('queuedJobs/charts'),
  get: id => DataService.get(`queuedJobs/get/${id}`),
  add: data => DataService.post('queuedJobs/save', data),
  edit: (id, data) => DataService.post(`queuedJobs/save/${id}`, data),
  delete: id => DataService.post(`queuedJobs/delete/${id}`),
};

// SefazCeDteMessages
const SefazCeDteMessages = {
  list: () => DataService.get('sefazCeDteMessages/list'),
  index: (params = {}) => DataService.get('sefazCeDteMessages/index', { params }),
  get: id => DataService.get(`sefazCeDteMessages/get/${id}`),
  add: data => DataService.post('sefazCeDteMessages/save', data),
  edit: (id, data) => DataService.post(`sefazCeDteMessages/save/${id}`, data),
  delete: id => DataService.post(`sefazCeDteMessages/delete/${id}`),
};

// SefazCeSigetReports
const SefazCeSigetReports = {
  list: () => DataService.get('sefazCeSigetReports/list'),
  index: (params = {}) => DataService.get('sefazCeSigetReports/index', { params }),
  get: id => DataService.get(`sefazCeSigetReports/get/${id}`),
  add: data => DataService.post('sefazCeSigetReports/save', data),
  edit: (id, data) => DataService.post(`sefazCeSigetReports/save/${id}`, data),
  delete: id => DataService.post(`sefazCeSigetReports/delete/${id}`),
};

// Tags
const Tags = {
  list: () => DataService.get('tags/list'),
  index: () => DataService.get('tags/index'),
  get: id => DataService.get(`tags/get/${id}`),
  add: data => DataService.post('tags/save', data),
  edit: (id, data) => DataService.post(`tags/save/${id}`, data),
  delete: id => DataService.post(`tags/delete/${id}`),
};

// Users
const Users = {
  recoverPassword: email => DataService.get(`users/recoverPassword/${email}`),
  resetPassword: (token, data) => DataService.post(`users/resetPassword/${token}`, data),
  token: data => DataService.post('users/token', data),
  me: () => DataService.get('users/me'),
  birthdays: () => DataService.get('users/birthdays'),
  list: (source = 'employees') => DataService.get(`users/list/${source}`),
  index: (source = 'employees', params = {}) => DataService.get(`users/index/${source}`, { params }),
  get: id => DataService.get(`users/get/${id}`),
  add: data => DataService.post('users/save', data),
  edit: (id, data) => DataService.post(`users/save/${id}`, data),
  photo: (id, data, { onUploadProgress = () => {} }) =>
    DataService.postFile(`users/photo/${id}`, data, { onUploadProgress }),
  signature: id => DataService.get(`users/signature/${id}`),
  delete: id => DataService.post(`users/delete/${id}`),
  employeesTransfer: data => DataService.post('users/employeesTransfer', data),
};

// UserAchievements
const UserAchievements = {
  index: user_id => DataService.get(`usersAchievements/index/${user_id}`),
  get: id => DataService.get(`usersAchievements/get/${id}`),
  add: data => DataService.post('usersAchievements/save', data),
  edit: (id, data) => DataService.post(`usersAchievements/save/${id}`, data),
  delete: id => DataService.post(`usersAchievements/delete/${id}`),
};

// UserClients
const UserClients = {
  get: id => DataService.get(`usersClients/get/${id}`),
  add: data => DataService.post('usersClients/save', data),
  edit: (id, data) => DataService.post(`usersClients/save/${id}`, data),
  delete: id => DataService.post(`usersClients/delete/${id}`),
};

// UserDepartments
const UserDepartments = {
  index: user_id => DataService.get(`usersDepartments/index/${user_id}`),
  get: id => DataService.get(`usersDepartments/get/${id}`),
  add: data => DataService.post('usersDepartments/save', data),
  edit: (id, data) => DataService.post(`usersDepartments/save/${id}`, data),
  delete: id => DataService.post(`usersDepartments/delete/${id}`),
};

// UserStatuses
const UserStatuses = {
  list: () => DataService.get('userStatuses/list'),
  index: () => DataService.get('userStatuses/index'),
  get: id => DataService.get(`userStatuses/get/${id}`),
  add: data => DataService.post('userStatuses/save', data),
  edit: (id, data) => DataService.post(`userStatuses/save/${id}`, data),
  delete: id => DataService.post(`userStatuses/delete/${id}`),
};

export default {
  Website,

  Accesses,
  Histories,

  Achievements,
  AchievementProducts,

  Addresses,

  Assets,
  AssetCategories,

  Cnaes,
  Comments,
  Contacts,
  Countries,
  Notifications,
  Newsletters,
  Tags,

  Cache,

  CalendarHolidays,

  CardTemplates,
  CardTemplateRecurrences,

  Cards,
  CardStatuses,
  CardTypes,
  CardPriorities,
  CardJustifications,
  CardJustificationReasons,
  CardReviews,
  CardMapViews,

  Clients,
  ClientStatuses,
  ClientTypes,

  ClientCardTemplates,

  ClientAttorneys,
  ClientAttorneySigners,
  ClientAttorneyTypes,
  ClientAttorneyGranteds,

  ClientCnpjs,
  ClientCnpjSizes,
  ClientCnpjStatuses,
  ClientCnpjLegalNatures,
  ClientCnpjMunicipalRegistrationStatuses,
  ClientCnpjStateRegistrationStatuses,
  ClientCnpjEmployerUnions,
  ClientCnpjEmployeeUnions,
  ClientCnpjEsocialSteps,

  ClientContracts,
  ClientContractItems,
  ClientContractItemTypes,

  ClientDigitalCertificates,
  ClientDigitalCertificateTypes,

  ClientUsers,
  ClientEmployees,
  ClientFiles,

  ClientLicenses,
  ClientLicenseTypes,

  ClientNegativeCertificates,
  ClientNegativeCertificateSigners,

  ClientPartners,
  ClientPartnerQualifications,

  ClientPartnerActs,
  ClientPartnerActSigners,

  ClientPasswords,

  ClientTaxations,
  ClientTaxationCalculationTypes,
  ClientTaxationFederalTypes,
  ClientTaxationStateTypes,
  ClientTaxationMunicipalTypes,
  ClientTaxationSocialSecurityTypes,

  ClientTaxBreaks,
  ClientTaxBreakSigners,

  EconomicGroups,
  EmailSents,

  Files,
  FileCategories,

  Departments,
  Profiles,

  OpenaiAssistants,
  OpenaiThreads,

  QuestionTypes,

  QueuedJobs,

  SefazCeDteMessages,
  SefazCeSigetReports,

  Users,
  UserAchievements,
  UserClients,
  UserDepartments,
  UserStatuses,
};
